<template>
  <div>
    <v-container class="faq-notice-container" style="display: block;">
        <br><br>
        <h1>Terms Of Use</h1>
        <small>Version 22.09.15. Last Updated 07 October 2015..</small>
        <br><br>
        <ul class="termsOfUseList" style="padding-left: 0px;">
          <li><strong>1. TERMS &amp; CONDITIONS OF USE</strong>
              <ul>
                  <li>1.1 By Using all or any portion of the App, Users are deemed to:
                      <ul>
                          <li>(a) Accept all of these Terms &amp; Conditions of Use;</li>
                          <li>(b) Agree that these Terms &amp; Conditions are contractually binding and enforceable by the Owner against Users and their Successors.</li>
                      </ul>
                  </li>
                  <li>1.2 Users are only permitted to Use the App:
                      <ul>
                          <li>(a) In accordance with these Terms &amp; Conditions of Use; and</li>
                          <li>(b) From or in a secure and virus free environment.</li>
                      </ul>
                  </li>
              </ul>
              <span class="alert-warning"><i class="fa fa-warning"></i>  If you do not agree with these Terms &amp; Conditions of Use, do not Use the App.</span>
          </li>
          <li><strong>2. DISCLAIMERS</strong>
              <ul>
                  <li>2.1 The App comes as is, with all faults and without any warranty against defects.</li>
                  <li>2.2 Users are advised to safeguard important data, to use the App with caution, and not to rely on, in any way, the correct functioning or performance of the App.</li>
                  <li>2.3 The Owner does not and cannot warrant the performance or results you may obtain by using the App, and is under no obligation to continue to develop, support or repair the App.</li>
                  <li>2.4 The Owner does not and cannot warrant that the App will be Useable or function correctly through all internet browsers or types of Computer. Including but without limitation the Owner does not and cannot warrant:
                      <ul>
                          <li>(a) the continued Useability or function of the App after third-party software updates are installed onto devices, including for example but without limitation, updates to Apple iOS; or</li>
                          <li>(b) that Location Services, ‘Push Notifications’ and ‘Pins’ will function correctly.</li>
                      </ul>
                  </li>
                  <li><strong>2.5 Legal compliance is the responsibility of the Licencee and/or User.</strong> Although the App may contain functions designed to facilitate or assist Users to achieve legal compliance, the Owner is not responsible for ensuring that Users comply with all laws or regulations, including those which require retention of business and personal records, data and information.</li>
                  <li><strong>2.6 Users are responsible for the accuracy of data entered into the App.</strong> Although the App relies entirely on accuracy of data entry by Users, the Owner does not check, and accepts no responsibility for, the accuracy of any data input by Users. When Using the App, Users must take appropriate care to ensure that data entry is accurate and correct.</li>
                  <li><strong>2.7 Users are responsible for approval and deletion of Dockets.</strong> The Owner discloses, and all Users agree, that:
                      <ul>
                          <li><strong>(a) Only Users control Docket approval.</strong> The Owner accepts no responsibility for the User incorrectly approving a Docket. Users should exercise appropriate care before approving Dockets, including double checking data inputs.</li>
                          <li><strong>(b) Only Users control deletion.</strong> The Owner accepts no responsibility for the User incorrectly deleting a Docket. Users should exercise appropriate care before approving Dockets, including double checking that the correct Docket is selected.</li>
                      </ul>
                  </li>
              </ul>
          </li>
          <li><strong>3. VIRTUALISATION TECHNOLOGY WEB HOSTING</strong>
              <ul>
                  <li>3.1 The Owner discloses, and all Users acknowledge, that the App:
                      <ul>
                          <li>(a) will be hosted on and Used through a Hosting Company;</li>
                          <li>(b) may be provided through the use of virtualisation technology; and</li>
                          <li>(c)      the hardware and software that the Owner uses to provide Use of the App may be shared with other Users.</li>
                      </ul>
                  </li>
                  <li>3.2       In this context, Users agree that the Owner may disclose information collected from them to third parties such as the Owner’s suppliers, contractors and third party service providers (or their suppliers). In some instances this may involve the transfer of personal information to a country outside of Australia, and Users consent to this.</li>
                  <li>3.3       Users must take all legally necessary steps to allow the Owner and its third party suppliers to collect information from them and to use, disclose, store and transfer such information in accordance with these Terms &amp; Conditions of Use. Users indemnify the Owner against any Claim or Liability which may arise in connection with their failure to do so.</li>
              </ul>
          </li>
          <li><strong>4.     RESTRICTIONS ON USE</strong>
              <ul>
                  <li>4.1       Distribution. These Terms &amp; Conditions of Use do not grant Users the right to sublicense or distribute the App. For information about obtaining the right to distribute the App on tangible media, through an internal network, together with another product, or by any other means, please contact the Owner.</li>
                  <li>4.2       Disabled Features. The App may contain features or functionalities that are hidden or appear disabled or ‘grayed out’ (‘Disabled Features’). Users must not access, or attempt to access, any Disabled Features other than through the use of enabling technologies supplied by the Owner. Additionally, Users must not rely on the App to create a feature substantially similar to any Disabled Feature or otherwise circumvent the technology that controls activation of any such feature.</li>
                  <li>4.3       Proprietary Notices. Users must not alter or remove any copyright or other proprietary notice that appears on or in the App.</li>
                  <li>4.4       No Modification or Reverse Engineering. Users must not reproduce, modify, adapt, translate, or create derivative works based upon the App. Additionally, Users must not reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code of the App. The App is copyrighted and trademarked in Australia.</li>
              </ul>
          </li>
          <li><strong>5.     NO TRANSFERS</strong>
              <p>Users may not reproduce, rent, lease, sublicense, assign or transfer its right to Use in the App, or authorise all or any portion of the App to be installed or copied onto any Computer except as may be expressly permitted by these Terms &amp; Conditions of Use and excluding storage of information by cookie or cache.</p>
          </li>
          <li><strong>6.     INTELLECTUAL PROPERTY</strong>
              <ul>
                  <li>6.1       The:
                      <ul>
                          <li>(a)      Product, and any authorised copies that Users may make; and</li>
                          <li>(b)      the structure, organisation, and code of the App;</li>
                      </ul>
                      <p>are the valuable intellectual property of the Owner and (in some cases) its suppliers.</p>
                  </li>
                  <li>6.2       The App is protected by law, including without limitation the copyright laws of Australia and other countries, and by international treaty provisions.</li>
                  <li>6.3       All Intellectual Property Rights, including Moral Rights, are expressly reserved by the Owner and its suppliers and nothing in these Terms &amp; Conditions of Use affects ownership of such rights.</li>
                  <li>6.4       Use of the App does not confer on Users any Intellectual Property Rights in the App.</li>
                  <li>6.5       Users must:
                      <ul>
                          <li>(a)      take all reasonable steps to ensure that all information provided by the Owner to enable Use of the App (including passwords, security certificates and software) is used properly and not disclosed to any person without the Owner’s prior consent; and</li>
                          <li>(b)      immediately notify the Owner if they become aware or suspect that information provided to Users by the Owner to enable Use of the App has been Used improperly or disclosed to any other person.</li>
                      </ul>
                  </li>
                  <li>6.6       Users agree that any breach of the Owners Intellectual Property Rights or the intellectual property provisions of these Terms &amp; Conditions of Use shall entitle the Owner not only to damages but also to injunct summarily the continued breach of its Intellectual Property Rights and/or these Terms &amp; Conditions of Use. Users must not in any such proceedings argue that the breach complained of is not one which should properly be the subject of summary injunctive relief.</li>
              </ul>
          </li>
          <li><strong>7.     THIRD-PARTY OFFERINGS</strong>
              <ul>
                  <li>7.1       The App may allow Users to access and operate with third party content, software applications, and data services, including rich internet applications <strong>('Third Party Offerings')</strong>.</li>
                  <li>7.2       Third Party Offerings are not owned or provided by the Owner. The Owner does not control, endorse, or accept responsibility for Third Party Offerings.</li>
                  <li>7.3       Users’ access to and use of any Third Party Offering, including any goods, services, or information, is governed by the Terms &amp; Conditions respecting such offerings, including copyright laws of Australia and other countries.</li>
                  <li>7.4       Any dealings between users and any third party in connection with a Third Party Offering, including such party’s privacy policies and use of personal information, delivery of and payment for goods and services, and any other terms, conditions, warranties, or representations associated with such dealings, are solely between Users and such third party.</li>
                  <li>7.5       Users agree not to buy any Third Party Offerings in violation of copyright laws of Australia or other countries.</li>
                  <li>7.6       The Owner or the third party may at any time, for any reason, modify or discontinue the availability of any Third Party Offerings.</li>
                  <li>7.7       Except as expressly agreed by the Owner, use of Third Party Offerings is at the Licensee and Users’ own risk and subject to the same disclaimers and limitations on liability as the App (refer to clause 2).</li>
              </ul>
          </li>
          <li><strong>8.     MISCELLANEOUS</strong>
              <ul>
                  <li>8.1       Any notice required to be given by these Terms &amp; Conditions of Use must be given in writing, including by email.</li>
                  <li>8.2       These Terms &amp; Conditions of Use shall be governed by and construed in accordance with the laws from time to time applying in the Jurisdiction. Users submit to the exclusive jurisdiction of the courts of the Jurisdiction and, where available, the Commonwealth of Australia, and those that have jurisdiction to hear any appeals from them.</li>
                  <li>8.3       Users may not attempt to offset any alleged entitlement against any monies owing to the Owner in connection with their Use of the App, including monies owing by way of damages, and must not withhold payment by reason only that they claim the alleged entitlement.</li>
                  <li>8.4       For all purposes connected with these Terms &amp; Conditions of Use, the Owner may provide or withhold its consent to the doing or execution of any act, matter or thing in its absolute discretion and on such terms as it shall deem fit, and a reference to the consent or approval of the Owner means the Owner’s prior written consent or approval.</li>
                  <li>8.5       There is no implied waiver by the Owner in respect of any provision of these Terms &amp; Conditions of Use and any delay or failure by the Owner to enforce any provision of these Terms &amp; Conditions of Use will not be deemed to be a waiver. Any waiver granted by the Owner:
                      <ul>
                          <li>(a)      must be in writing;</li>
                          <li>(b)      shall be without prejudice to any other rights; and</li>
                          <li>(c)      is only effective in relation to the particular obligation or breach in respect of which it is given and does not cover subsequent breaches of the same or a different kind.</li>
                      </ul>
                  </li>
                  <li>8.6       No variation to, modification or consent to any departure from any provision of these Terms &amp; Conditions of Use (including this clause) by the Owner shall in any event be of any force or effect unless the same shall be confirmed in writing and signed by the Owner and then such variation, modification or consent shall be effective only to the extent for which it may be made or given.</li>
                  <li>8.7       If any of the provisions of these Terms &amp; Conditions of Use shall be unlawful, void or unenforceable for any reason, the provision shall be deemed severable to the extent that it is unlawful, void or unenforceable, but shall not affect the validity or enforceability of the remaining provisions.</li>
              </ul>
          </li>
          <li>
          </li><li><strong>9.     DEFINITIONS</strong>
              <ul>
                  <li>9.1       In these Terms &amp; Conditions of Use, unless the context otherwise requires or the contrary intention appears:</li>
              </ul>
              <p><strong>Claim </strong>means any claim, demand, regulatory proceeding, action or cause of action or anything of a similar nature.</p>
              <p><strong>Cloud Environment</strong> means a hosted computing service allowing distributed computing over a network, with the ability to run a program on many connected computers at the same time from a remote location.</p>
              <p><strong>Computer</strong> means any electronic device that accepts, processes, stores or outputs data, including smartphones and tablets.</p>
              <p><strong>Contract</strong> means the agreement between the Owner and the Licencee in respect of the App comprising of and evidenced by this Agreement and the Licence Agreement.</p>
              <p><strong>Docket</strong> means the feature of the App that is a digital certificate. It gives particulars of the works and acts as an invoice and receipt.</p>
              <p><strong>Hosting Company</strong> means a company that provides internet connectivity, and space on a server, for use by the Owner.</p>
              <p><strong>Intellectual Property Rights</strong> means any statutory and other proprietary rights in respect of registered and unregistered trademarks, patents, circuit layouts, software, designs, copyrights, know-how and all other intellectual property rights as defined in Article 2 of the Convention Establishing the World Intellectual Property Organisation of July 1967.</p>
              <p><strong>Jurisdiction</strong> means the Australian Capital Territory.</p>
              <p><strong>Legal Fees</strong> means legal fees, costs and disbursements on the higher of a full indemnity basis or a solicitor and own client basis, determined without taxation, assessment, or similar process.</p>
              <p><strong>Liability</strong> means any liability, loss, damage, expense or anything of a similar nature (including without limitation settlement costs and Legal Fees) sustained or incurred at any time, actually or contingently.</p>
              <p><strong>Licencee</strong> means the entity which is party to the Licence Agreement with the Owner.</p>
              <p><strong>Licence Agreement</strong> means the agreement entered into by the Owner and the Licencee dealing with the Licence Fee and other related commercial terms.</p>
              <p><strong>Location Services</strong> means any software feature that uses the geographical position of the Computer to control features and for information generally.</p>
              <p><strong>Moral Right</strong> means:
              </p><ul>
                  <li>(a)      a right of attribution of authorship; or</li>
                  <li>(b)      a right not to have authorship falsely attributed; or</li>
                  <li>(c)      a right of integrity of authorship; or</li>
                  <li>(d)      a right of a similar nature;</li>
              </ul>
              <p></p>
              <p>which is conferred by statute, and which exists or comes to existence anywhere in the world in a deliverable form.</p>
              <p><strong>Owner</strong> means Record TIME Pty Ltd (ACN 604 582 649).</p>
              <p><strong>Pins</strong> means any software feature that allows Users to locate a position on a map and subsequently mark that location.</p>
              <p><strong>Product</strong> means the ‘Record Time’ application to which these Terms &amp; Conditions of Use pertain, including all associated code, algorithms, third party offerings, explanatory materials and files, and all upgrades, updates, modifications, additions and copies of the foregoing.</p>
              <p><strong>Push Notifications </strong> means any software feature which allows an application to deliver messages or events or similar to Users even when the User is not actively using the application.</p>
              <p><strong>Successors</strong> means heirs, executors, administrators, liquidators (including provisional liquidators), receivers, successors, controllers, assigns, trustees and persons taking by way of novation.</p>
              <p><strong>Terms &amp; Conditions</strong> of Use means the provisions of this document as varied and in force from time to time.</p>
              <p><strong>Use</strong> means to access, use, install, download, copy, distribute, disseminate or otherwise benefit from the functionality of the App.</p>
              <p><strong>User</strong> means the Licencee and any and all other persons who Use the App.</p>
              <p><strong>User Information</strong> means any information, confidential or otherwise, that the Owner obtains from the Licencee or a User in connection with Use of the App, including information which the Licencee or a User uploads to, stores in or transmits through the App and all data input into the App.</p>
          </li>
          <li><strong>10.    INTERPRETATION</strong>
              <p>In these Terms &amp; Conditions of Use, unless the context otherwise requires or the contrary intention appears:</p>
              <ul>
                  <li>(a)      the singular includes the plural and vice versa;</li>
                  <li>(b)      a reference to an individual or person includes a corporation, partnership, joint venture, association, authority, trust, state or government and vice versa;</li>
                  <li>(c)      where an expression is defined, another part of speech or grammatical form of that expression has a corresponding meaning;</li>
                  <li>(d)      “including” and similar expressions are not and must not be treated as words of limitation;</li>
                  <li>(e)      headings and tables of contents are inserted for convenience only and have no effect on interpretation;</li>
                  <li>(f)       any covenant or agreement by a party not to do an act or thing shall be deemed to include an obligation not to permit such act or thing to be done and to use its best endeavours to prevent such act or thing being done by another person;</li>
                  <li>(g)      a reference to a statute, regulation, proclamation, ordinance or by-law includes all statutes, regulations, proclamations, ordinances or by-laws amending, consolidating or replacing it, and a reference to a statute includes all regulations, proclamations, ordinances and by-laws issued under that statute; and</li>
                  <li>(h)      the term “notice” shall include notice, authorisation, request, nomination, notification and any other form of communication between the parties.</li>
              </ul>
          </li>
        </ul>
    </v-container>
  </div>
</template>

<style lang="scss">
.termsOfUseList{
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    font-weight: 400;

    li {
        margin-bottom: 25px;
        margin-left: 0px;
    }
    strong{
      margin-bottom: 10px;
      display: block;
    }
    ul{
      list-style: none;
      margin-left: 0px;
      padding-left: 0px;

      li{
        margin-bottom: 10px;

        ul{
          margin-left: 20px;
        }
      }
    }
}
</style>

<script>
// @ is an alias to /src

export default {
  metaInfo: {
    title: 'Contact',
    titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: ''}
    ]
  },
  components: {
      
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
